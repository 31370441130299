import { MASTER_CLIENT_FEATURE } from "commonlib";
import Dashboard from "components/index/Dashboard";
import AuthGuard from "guards/AuthGuard";
import FeatureGuard from "guards/FeatureGuard";
import MasterClientGuard from "guards/MasterClientGuard";
import MainLayout from "layouts/main/MainLayout";
import type { NextPage } from "next";

const Home: NextPage = () => {
  return (
    <AuthGuard>
      <MainLayout>
        <MasterClientGuard>
          <Dashboard />
        </MasterClientGuard>
      </MainLayout>
    </AuthGuard>
  );
};

export default Home;
