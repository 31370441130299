import { LoadingButton } from "@mui/lab";
import { Divider, Icon, IconButton, Typography } from "@mui/material";
import RIcon from "common/misc/RIcon";
import { ReactNode } from "react";

export default function ModalTitle(props: {
  title: string | ReactNode;
  onBack?: () => void;
  onSave?: () => void;
  loading?: boolean;
  customSaveButton?: any;
  closeButton?: "BACK" | "CROSS";
  noSaveButton?: boolean;
  sticky?: boolean;
  saveButtonProps?: React.ComponentProps<typeof LoadingButton>;
}) {
  const closeButton = props.closeButton || "BACK";
  return (
    <>
      <div
        className="draggable-handle"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2px 0px",
          ...(props.sticky
            ? { background: "white", position: "sticky", top: 0, zIndex: 5 }
            : {}),
          // backgroundColor: "white",
        }}
      >
        {closeButton === "BACK" && (
          <IconButton onClick={props.onBack} sx={{ pointerEvents: "auto" }}>
            <Icon>arrow_back</Icon>
          </IconButton>
        )}
        {typeof props.title === "string" ? (
          <Typography>{props.title}</Typography>
        ) : (
          props.title
        )}
        <div style={{ flexGrow: 1 }}></div>
        {props.customSaveButton}

        {!props.noSaveButton && (
          <LoadingButton
            // disabled={objForm ? !objForm?.formState.isDirty: undefined}
            loading={props.loading}
            type="submit"
            onClick={props.onSave}
            {...props.saveButtonProps}
          >
            Save
          </LoadingButton>
        )}

        {closeButton === "CROSS" && (
          <IconButton
            onClick={props.onBack}
            sx={{ pointerEvents: "auto", marginLeft: 1 }}
          >
            <RIcon name="close" color="primary" />
          </IconButton>
        )}
      </div>
      <Divider sx={{ marginBottom: 2 }} />
    </>
  );
}
