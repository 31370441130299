import { Button, Typography } from "@mui/material";
import Loading from "components/assets/Loading";
import { useAuth } from "contexts/AuthContext";
import React, { ReactNode } from "react";
import AuthServices from "services/AuthServices";
import { AuthStore } from "stores/authStore";
import LoadingSkeleton from "./LodingSkeleton";

export default function MasterClientGuard(props: { children: ReactNode }) {
  const auth = useAuth();
  const { selectedMasterClient } = AuthStore.useState();

  const access = AuthStore.getRawState().user?.Access?.MasterClients || [];

  if (!auth.isUserDataFetched) return <Loading />; // Loading

  if (access?.length === 0) {
    {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ p: 2, fontWeight: "bold" }}>
            This page is not for you
          </Typography>
          <Button
            sx={{ fontWeight: "bold" }}
            onClick={() => {
              AuthServices.onLogout();
            }}
          >
            Log Out
          </Button>
        </div>
      );
    }
  }

  if (auth.user?._id && selectedMasterClient) {
    return (
      <React.Fragment key={selectedMasterClient?._id}>
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" sx={{ p: 2, fontWeight: "bold" }}>
        No Master Client is Selected
      </Typography>
    </div>
  );
}
